@mixin flex($align: initial, $justify: initial, $direction: initial) {
	display: flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
}

@mixin inlineFlex($align: initial, $justify: initial, $direction: initial) {
	display: inline-flex;
	align-items: $align;
	justify-content: $justify;
	flex-direction: $direction;
}

@mixin row($gap: -15px) {
	display: flex;
	flex-wrap: wrap;
	margin-left: $gap;
	margin-right: $gap;
}

@mixin column($size: 100%, $gap: 15px) {
	max-width: $size;
	flex-basis: $size;
	padding-left: $gap;
	padding-right: $gap;
}

@mixin title($size: initial, $weight: initial, $spacing: initial, $height: initial) {
	font-family: 'Poppins', sans-serif;
	font-size: $size;
	font-weight: $weight;
	letter-spacing: $spacing;
	line-height: $height;
}

@mixin text($size: initial, $weight: initial, $spacing: initial, $height: initial) {
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	font-weight: $weight;
	letter-spacing: $spacing;
	line-height: $height;
}

@mixin position($position: initial, $top: initial, $right: initial, $bottom: initial, $left: initial) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin xs {
	@media all and (max-width: 576px) {
		@content;
	}
}

@mixin sm {
	@media all and (min-width: 576px) {
		@content;
	}
}

@mixin md {
	@media all and (min-width: 768px) {
		@content;
	}
}

@mixin lg {
	@media all and (min-width: 992px) {
		@content;
	}
}

@mixin xl {
	@media all and (min-width: 1200px) {
		@content;
	}
}

@mixin xxl {
	@media all and (min-width: 1400px) {
		@content;
	}
}

@mixin minMedia($size) {
	@media all and (min-width: $size) {
		@content;
	}
}

@mixin maxMedia($size) {
	@media all and (max-width: $size) {
		@content;
	}
}
