/* You can add global styles to this file, and also import other style files */
@import 'theme';
@import 'mixin';
@import 'swiper/scss';

:root {
	--g-pad-inline: 24px;
}

* {
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
	list-style: none;
	margin: 0px;
	padding: 0px;
	text-decoration: none;
	@include text(14px);
}

*::before,
*::after {
	box-sizing: border-box;
}

body {
	color: $gray2;
}

button,
input,
input:focus {
	background-color: transparent;
	background-image: none;
	border: none;
	box-shadow: none;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

i {
	font-style: normal;
}

/* SANTANDER */
.page {
	margin: 0 auto;
	max-width: 768px;
	min-height: 100vh;
	@include flex(initial, initial, column);

	&__body {
		flex-grow: 1;
		@include flex(initial, initial, column);
	}

	&__footer {
		border-top: 1px solid $border;

		& .wrapper {
			padding: 16px 24px;
		}
	}
}

.wrapper {
	box-sizing: content-box;
	// padding: 24px 24px 0;
	padding: 10px var(--g-pad-inline) 0;
}

.section {
	margin: 0 auto;
	width: 100%;
}

/* BUTTONS */
button {
	cursor: pointer;
	transition: 0.5s;

	&:hover,
	&:active,
	&:focus {
		transition: 0.5s;
	}
}

.button {
	background: $red;
	border-radius: $bdRadius;
	color: $white;
	height: 48px;
	@include text(16px, 600, initial, 16px);

	&--disable {
		background: $grayc;
		border-radius: $bdRadius;
		color: $white;
		cursor: not-allowed;
		height: 48px;
		transition: all 0.5s;
		@include text(16px, 600, initial, 16px);
	}
}

.buttonGhost {
	background: transparent;
	border: 1px solid transparent;
	border-radius: $bdRadius;
	color: $gray2;
	height: 48px;
	@include text(14px, 400, initial, 16px);
}

.buttonLight {
	border: 1px solid $red;
	border-radius: $bdRadius;
	color: $red;
	height: 48px;
	@include text(16px, 600, initial, 16px);
}

/* TEXTS */
.title {
	@include title(20px, 600, -0.5px, 28px);
}

.titleLight {
	@include text(20px, 400, initial, 28px);
}

.subtitle {
	@include title(24px, 600, -1px, 32px);
}

.namePage {
	color: $gray76;
	margin-bottom: 4px;
	@include text(16px, 700, 0.3px, 24px);
}

/* ACTION - como filtros no header e busca + ver cardápio e horários */
.action {
	color: $gray2;
	@include inlineFlex(center, initial, initial);

	&__icon {
		font-size: 21px !important;
		// height: 24px;
		margin-right: 8px;
		// padding: 1.5px 0.5px;
		// width: 24px;

		&.fa-info {
			font-size: 16px !important;
			@include flex(center, center, initial);
		}
	}

	&__text {
		@include text(14px, 400, initial, 18px);
	}
}

/* SLIDES STYLES */
.slide {
	&__control {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
		height: 40px;
		width: 100%;
		z-index: 2;
		@include flex(center, center, initial);
		@include position(absolute, inherit, inherit, 0, inherit);

		&__item {
			background: $grayc;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			height: 8px;
			margin-right: 4px;
			width: 8px;

			&--checked {
				background: $red;
			}
		}
	}
}

/* SWITCH */
.switch {
	@include flex(center, space-between, initial);

	&__txt {
		color: $gray8;
		width: calc(100% - 60px);
		@include text(14px, 400, initial, 19px);
	}

	&__input {
		height: 0;
		opacity: 0;
		width: 0;
	}

	&__round {
		background-color: #c7c7c7;
		// border: 1px solid $graye;
		border-radius: 13.5px;
		cursor: pointer;
		height: 28px;
		transform: translate(0px);
		transition: 0.4s;
		width: 59px;
		@include flex();

		&::before {
			content: '';
			background: $white;
			border: 1px solid $white;
			border-radius: 50%;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
			height: 32px;
			transition: transform 0.4s;
			transform: translate(0, -2px);
			width: 32px;
			@include flex();
		}
	}

	.switch__input:checked + .switch__round {
		background-color: $red;

		&::before {
			transform: translate(28px, -2px);
		}
	}

	.switch__input:focus + .switch__round {
		box-shadow: 0 0 1px $graye;
	}
}

/* TOOLTIP */
.tooltip {
	background: $bgTolltip;
	border-radius: $bdRadius;
	height: 56px;
	padding: 12px 16px;
	width: 225px;

	&__text {
		@include text(12px, 400, initial, 16px);
	}
}

.spacer {
	height: 24px;
	width: 100%;
}

.divider {
	border-top: 1px solid $border;
}
