$black: #000;
$gray0: #000;
$gray1: #111;
$gray2: #222; /* padrão Santander */
$gray3: #333;
$gray4: #444;
$gray5: #555;
$gray6: #666;
$gray76: #767676;
$gray7: #777;
$gray8: #888;
$gray9: #999;
$graya: #aaa;
$grayb: #bbb;
$grayc: #ccc;
$grayd: #ddd;
$graye: #eee;
$grayf: #fff;
$grayF6: #f6f6f6;
$white: #fff;

$red: #cc0000;
$txtRed: #a63449;
$error: #f14c6a;
$bgRed: #fcdbe1;

$bgTolltip: #ebf0ff;
$blue: #3366ff;
$bgBlue: rgba(51, 102, 255, 0.1);

$green: #3a8340;
$bgGreen: #f0f8f0;

$border: #f0f0f0;
$bdRadius: 4px;

:root {
	--green: #{$green};
}
